import React, { useState, useEffect } from "react";
import { Dialog, Button } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({
  open,
  onClose,
  previewImage,
  useDialog,
  isPdf,
  setPageNumber,
  pageNumber,
}) => {
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState(null);  // Store blob URL for Base64 PDF
  const [numPages, setNumPages] = useState(null);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getFileType = (url) => {
    if (url?.startsWith("data:application/pdf;base64")) {
      return "pdf";
    } else if (url?.endsWith(".pdf")) {
      return "pdf";
    } else if (url?.endsWith(".jpg") || url?.endsWith(".jpeg") || url?.endsWith(".png")) {
      return "image";
    } else {
      return "";
    }
  };

  // Convert Base64 to Blob URL
  const createBlobUrl = (base64) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  };

  useEffect(() => {
    if (previewImage) {
      const type = getFileType(previewImage);
      setFileType(type);

      if (type === "pdf" && previewImage.startsWith("data:application/pdf;base64,")) {
        const base64 = previewImage.split(",")[1];
        const url = createBlobUrl(base64);
        setFileUrl(url);
      } else {
        setFileUrl(previewImage);  // URL if not Base64
      }
    }
  }, [previewImage]);

  const renderFilePreview = () => {
    if (fileType === "pdf") {
      return (
        <embed
          src={fileUrl}
          type="application/pdf"
          width="100%"
          height="500px"
        />
      );
    } else if (fileType === "image") {
      return (
        <img
          src={fileUrl}
          alt="File preview"
          style={{ maxWidth: "100%", maxHeight: "600px" }}
        />
      );
    }
  };

  if (useDialog) {
    return (
      <Dialog open={open} onClose={onClose}>
        {isPdf ? (
          <div>
            <div style={{ height: "500px", width: "700px", overflow: "auto" }}>
              <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "5px 0px",
                gap: "5px",
              }}
            >
              <Button
                onClick={previousPage}
                disabled={pageNumber <= 1}
                variant="contained"
                color="primary"
              >
                Previous
              </Button>
              <Button
                onClick={nextPage}
                disabled={pageNumber >= numPages}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </div>
          </div>
        ) : (
          <img alt="file" src={fileUrl} />
        )}
      </Dialog>
    );
  } else {
    return <div>{renderFilePreview()}</div>;
  }
};

export default PdfViewer;
