import React, { useState } from "react";
import { Logger } from "logging-library";
import {
  Box,
  Button,
  Container,
  Dialog,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import axios from "axios";
import useStyles from "../../assets/css/MaskAadhaar";
import FormData from "form-data";
import Form from "../Form/Form";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const maskingAPI = process.env.REACT_APP_BACKEND_URL || "https://endpoint-gateway-stage.finhub.habilelabs.io/v1";

const MaskAadhaar = () => {
  const [numPages, setNumPages] = useState(null);
  const [response, setResponse] = useState(null);
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const ImgData = localStorage.getItem("imgData");
  const parseData = JSON.parse(ImgData);
  const fileType = parseData[1];
  const [isFormOpen, setIsFormOpen] = useState(false);
  
  const currentFile = response?.downloadUrl || parseData[0];
  const isPdf = fileType === 'pdf' || fileType === 'PDF' || 
               currentFile?.endsWith('.pdf') || currentFile?.endsWith('.PDF') ||
               (typeof currentFile === 'string' && currentFile.startsWith('data:application/pdf'));
  const isMasked = !!response?.downloadUrl;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changeImage = () => {
    navigate("/");
  };
  
  const openFormField = () => {
    setIsFormOpen(true);
  }
  
  const apiData = async (formData) => {
    if (!location.state || !location.state.fileData) {
      alert("No file data available.");
      return;
    }

    const data = new FormData();
    data.append("file", location.state.fileData);
    let maskingEndPoint = maskingAPI + '/aadhaar-masking';
    
    try {
      const response = await axios.post(maskingEndPoint, data, {
        maxBodyLength: Infinity,
        "headers": {
                "aadhaar-masking-key": "NqCfTO4VYnUaQkcSEZrvV0y89uWEdT"
              },
      });
      
      if (response.data.status === 200) {
        setResponse(response.data);
        setIsFormOpen(false);
        navigate("/compare", {
          state: {
            ...response.data,
            originalFile: parseData[0],
            originalFileType: fileType
          }
        });
      } else {
        alert(response.data.message || "Masking failed. Please try again.");
      }
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  return (
    <Box className={classes.common} p="2%">
      <Container
        maxWidth="lg"
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ maxWidth: "80%", m: "auto 5%" }}>
          <img
            src={logo}
            alt=""
            style={{ maxWidth: "70px", maxHeight: "63px" }}
          />
        </Box>
        <Box flexGrow="1" position="relative">
          <Box className={classes.secondChild} p="25px 0" mx="auto">
            <Box padding="20px">
              <Typography variant="h4" color="#11111" textAlign="center">
                Mask your aadhaar with just one click!
              </Typography>
              <Typography variant="body1" color="grey" textAlign="center">
                Accepted file types: .png, .jpg, .jpeg, .pdf (Single Page)
              </Typography>
            </Box>

            <Box
              width="100%"
              height="400px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="20px 0"
              sx={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}
            >
              {isPdf ? (
                <Box width="100%" height="100%" display="flex" justifyContent="center">
                  <Document
                    file={currentFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Typography>Loading Aadhaar...</Typography>}
                    noData={<Typography>No Aadhaar found</Typography>}
                  >
                    <Page 
                      pageNumber={1} 
                      width={350}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Document>
                </Box>
              ) : (
                <img
                  src={currentFile}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain"
                  }}
                  alt={isMasked ? "Masked document" : "Original document"}
                />
              )}
            </Box>

            <Box className={classes.buttonParent} marginTop="20px">
              <Button
                variant="outlined"
                sx={{
                  color: "#02174E",
                  ":hover": { background: "#2EAEE9" },
                  fontSize: { xs: "10px", md: "14px" },
                }}
                onClick={changeImage}
              >
                Change Image
              </Button>
              {!isMasked && (
                <Button
                  variant="contained"
                  sx={{
                    color: "#02174E",
                    background: "#2EAEE9",
                    ":hover": { background: "#2EAEE9" },
                    fontSize: { xs: "10px", md: "14px" },
                  }}
                  onClick={openFormField}
                >
                  Mask Aadhaar
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%" } }}
        maxWidth="xs"
        open={isFormOpen}
      >
        <Form
          handleClose={() => setIsFormOpen(false)}
          apiData={apiData}
        />
      </Dialog>
    </Box>
  );
};

export default MaskAadhaar;