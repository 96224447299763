import { Box, Container, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import logo from "../../assets/logo.svg";
import PdfViewer from "../pdfViewer.js/pdfViewer";

const Compare = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    originalFile = '',
    originalFileType = '',
    downloadUrl: maskedFile = '',
    fileName = 'masked_aadhaar.pdf',
  } = location.state || {};

  useEffect(() => {
    if (!location.state?.downloadUrl || !location.state?.originalFile) {
      navigate('/');
    }
  }, [location.state, navigate]);

  const isPdf = originalFileType === "pdf";

  const downloadFile = async (url, fileName) => {
    setIsDownloading(true);
    try {
      // First try the direct download approach
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Check if download was successful
      setTimeout(() => {
        if (!document.querySelector(`iframe[src="${url}"]`)) {
          const shouldOpen = window.confirm(
            'Download might be blocked. Open in new tab to save manually?'
          );
          if (shouldOpen) {
            window.open(url, '_blank');
          }
        }
        setIsDownloading(false);
      }, 1000);
    } catch (error) {
      setIsDownloading(false);
      window.open(url, '_blank');
      alert("Download failed. The file has been opened in a new tab. Please use the browser's save option.");
    }
  };

  const changeImage = () => navigate("/");

  const handleDownload = () => downloadFile(maskedFile, fileName);

  return (
    <Box p="2%">
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "90%", m: "auto" }}>
          <Box>
            <img src={logo} alt="Company Logo" style={{ maxWidth: "70px", maxHeight: "63px" }} />
          </Box>
          <Button
            variant="contained"
            href="https://www.habilelabs.io/contact-us"
            sx={{
              background: "#2EAEE9",
              ":hover": { background: "#2EAEE9" },
              fontSize: { xs: "0.600rem", md: "0.875rem" }
            }}
          >
            Contact Us
          </Button>
        </Box>

        {/* Comparison Panel */}
        <Paper elevation={3} sx={{
          display: "flex",
          maxWidth: "90%",
          m: "30px auto",
          flexDirection: "column",
          height: { xs: "auto", md: "475px" },
        }}>
          <Box sx={{
            flex: 1,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "stretch"
          }}>
            {/* Before Section - Original File */}
            <Box width="100%" sx={{
              background: "#F2FBFF",
              p: { xs: 3, md: 2 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}>
              <Typography variant="h5" sx={{
                px: 3,
                py: 1,
                bgcolor: "#E1F0F9",
                color: "#02174E",
                borderRadius: 1,
                boxShadow: "#02174E -3px 3px",
                mb: 3
              }}>
                Before
              </Typography>
              
              <Box sx={{
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "auto"
              }}>
                {isPdf ? (
                  <PdfViewer
                    open={true}
                    onClose={() => {}}
                    previewImage={originalFile}
                    useDialog={false}
                    isPdf={true}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    width="100%"
                  />
                ) : (
                  <img
                    src={originalFile}
                    alt="Original Aadhaar"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      display: "block"
                    }}
                  />
                )}
              </Box>
            </Box>

            {/* After Section - Masked File */}
            <Box width="100%" sx={{
              background: "#E8ECEE",
              p: { xs: 3, md: 2 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}>
              <Typography variant="h5" sx={{
                px: 3,
                py: 1,
                bgcolor: "#E1F0F9",
                color: "#02174E",
                borderRadius: 1,
                boxShadow: "#02174E -3px 3px",
                mb: 3
              }}>
                After
              </Typography>
              
              <Box sx={{
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "auto"
              }}>
                {isPdf ? (
                  <PdfViewer
                    open={true}
                    onClose={() => {}}
                    previewImage={maskedFile}
                    useDialog={false}
                    isPdf={true}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    width="100%"
                  />
                ) : (
                  <img
                    src={maskedFile}
                    alt="Masked Aadhaar"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      display: "block"
                    }}
                  />
                )}
              </Box>
              
              <Box display="flex" gap={2} mt={3}>
                <Button
                  variant="outlined"
                  sx={{
                    color: "#02174E",
                    ":hover": { bgcolor: "#2EAEE9" },
                    fontSize: { xs: "0.600rem", md: "0.875rem" }
                  }}
                  onClick={changeImage}
                >
                  Change Image
                </Button>
                <Button
                  variant="contained"
                  disabled={isDownloading}
                  sx={{
                    color: "#02174E",
                    bgcolor: "#2EAEE9",
                    ":hover": { bgcolor: "#2EAEE9" },
                    fontSize: { xs: "0.600rem", md: "0.875rem" }
                  }}
                  onClick={handleDownload}
                >
                  {isDownloading ? 'Downloading...' : 'Download'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Compare;